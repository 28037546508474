.article-box{
  display: block;

  &__data{
    padding-#{$left}: 300px;
    position: relative;
    @include respond-to('medium'){
      padding-#{$left}: 0px;
    }
  }
  &__title{
    font-size: 30px;
    color: #10203f;
    line-height: 1;
    margin-bottom: 25px;
    @include respond-to('small'){
      font-size: 22px;
    }
  }
  &__text{
    font-size: 20px;
    color: #10203f;
    @include respond-to('small'){
      font-size: 18px;
    }
  }
  &__more{
    font-size: 25px;
    font-weight: bold;
    color: #8f292b;
    position: absolute;
    left: 0;
    bottom: 0;
    @include respond-to('medium'){
      position: static;
    }
    @include respond-to('small'){
      font-size: 22px;
    }
  }
  &__hr{
    border-color: #10203f;
    margin: 40px 0;
    @include respond-to('medium'){
      margin: 20px 0;
    }
  }
}
