// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
/// Regular font family
/// @type List
$text-font-stack: 'Assistant', sans-serif !default;
$custom-font-stack: 'oe_rutz_promedium', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
/// @type Color
$main-color: #000000 !default;
$text-color: #000000 !default;
$brand-color: #000000 !default;
$light-red-color: #bf311a !default;
$color-red: #bf311a !default;
$red-color: #bf311a !default;
$mid-grey-color: rgb(153, 153, 153) !default;
$dark-red-color: #551900 !default;

//@type general
$base-zindex: 100;
$max-width: 1230px !default;
$center-margin: 0 auto;
$padding-base: 15px;
$padding-base-box: 20px;
//header
$header-position: fixed !default;
$site-default-top-padding: 101px !default;
$site-mobile-default-top-padding: 90px !default;
$section-spacer-height:55px !default;
$row-spacer-height:20px !default;
//hr
$hr-full-height: 1px !default;
$hr-full-bg: #c1c1c1!default;
$hr-border-color: #fff !default;
$hr-margin:20px 0px !default;
//inner page
$inner-content-holder-width: 77.5% !default;
$inner-content-aside-width: 22.5% !default;
//footer
$footer-padding-top:60px !default;
$footer-min-height:360px !default;
$footer-bg:#fff !default;
//@type breakpoints
$breakpoints: (
  'mobile-m': (max-width: 375px),
  'mobile-l': (max-width: 425px),
  'extra-small': (max-width: 480px),
  'small': (max-width: 768px),
  'medium': (max-width: 992px),
  'large': (max-width: 1200px),
  'larger': (max-width: 1300px),
  'huge': (max-width: 1400px),
) !default;
//@type font sizes
$font-size-base:          16px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

// social colors
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #1ab7ea;
$tumblr: #32506d;
$instagram: #bc2a8d;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;

@if $dir != ltr and $dir != rtl {
    $dir: ltr;
}

$left: if-ltr(right, left);
$right: if-ltr(left, right);
