footer{
  width:100%;
  position: relative;
}

.wrapper-ftr-data{
  background-color: #1f2e49;
}

.ftr-info{
  font-size: 30px;
  font-weight: 200;
  color: #fff;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  @include respond-to('small'){
    font-size: 18px;
  }
}

.wrap-ftr-checkbox{
  margin-top: 20px;
  @include respond-to('medium'){
    text-align: center;
  }
  @include respond-to('small'){
    text-align: $right;
  }
}

.ftr-check-uncheck{
  position: relative;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin-#{$left}: 5px;
  @include respond-to('small'){
    float: $right;
  }
  label {
    width: 23px;
    height: 23px;
    cursor: pointer;
    position: absolute;
    background-color: transparent;
    border: 1px solid #fff;
    #{$left}: 0;
    top: 0;
    &:after {
      content: '';
      width: 12px;
      height: 6px;
      position: absolute;
      top: 6px;
      #{$left}: 5px;
      border: 2px solid #fff;
      border-top: none;
      border-#{$right}: none;
      background: 0 0;
      opacity: 0;
      transform: rotate(-45deg);
    }
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}

.ftr-checkbox-para{
  display: inline-block;
  @include respond-to('small'){
    display: block;
  }
  & > p{
    font-size: 20px;
    color: #fff;
    margin: 0;
    font-weight: 300;
    @include respond-to('small'){
      font-size: 16px;
    }
  }
}
