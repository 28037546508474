.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after{
  background-color: #214678;
}

.sb-slidebar{
  background-color: #f5f5f5;
  z-index: 999;
}

.mobile-menu-btn{
  float: $right;
  padding: 18px 15px 18px 10px;
}

.sb-right .sb-menu,
.sb-left .sb-menu{
  & li{
    border-bottom: 0;
    & a{
      color: #214678;
      border-bottom: 1px solid rgba(0,0,0,.1);
      font-size: 15px;
      &:hover{
        background-color: #eee;
      }
    }
    & ul{
      li{
        padding-right: 0;
      }
    }
  }
  & ul{
    padding: 0;
    li > a{
      padding-right: 35px;
    }
  }
}
