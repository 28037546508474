.banner-top-content{
  position: relative;
  width: 100%;
  min-height: 550px;
  @include respond-to('small'){
    min-height: inherit;
  }
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left:0;
    height: 350px;
    width: 100%;
    background: url('../images/banner-shadow.png') no-repeat;
    z-index: 9;
  }
}

.banner-top-content__image{
  background-position: center center;
  background-size: cover;
  height: 550px;
  -webkit-box-shadow: inset 0px -10px 20px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: inset 0px -10px 20px 0px rgba(0,0,0,0.15);
  box-shadow: inset 0px -10px 20px 0px rgba(0,0,0,0.15);
}
