.wrapper-newslatter-form{
  background-color: #7998b2;
  position: relative;
  padding: 60px 0;
  &__icon-form{
    text-align: center;
    margin-bottom: 10px;
  }
  &__title{
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    text-align: center;
  }
  &__sub-title{
    font-size: 30px;
    color: #fff;
    line-height: 1;
    text-align: center;
    margin-bottom: 5px;
  }
  &__sub-title2{
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
  }
}

.newslatter-form{
  padding: 0 150px;
  position: relative;
  & .form-group{
    margin-#{$left}: 15px;
  }
  &__input{
    width: 280px !important;
    height: 39px;
    border-radius: 0;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    &::placeholder{
      font-size: 20px;
      color: #a8c5dd;
      transition: all 0.3s;
    }
    &:focus{
      outline: none;
      border-color: #fff;
      box-shadow: none;
      &::placeholder{
        opacity: 0;
      }
    }
  }
  &__send{
    width: 280px;
    height: 70px;
    border: 1px solid #fff;
    font-size: 45px;
    color: #fff;
    background-color: transparent;
    margin-top: 45px;
    &:focus{
      outline: none;
    }
    &:active{
      @include btn-effect-active;
    }
  }
}
