// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

body {
 color: $brand-color;
 font: normal $font-size-base $text-font-stack;
 direction: if-rtl(rtl, ltr);
 background: url('../images/body-bg.jpg') top/cover;
 width: 100%;
 height: 100%;
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $main-color;

  @include on-event {
    color: #000;
    text-decoration: none;
    outline: none;
  }
}

.site-wrapper{
  width:100%;
}
