.main-header{
  .header-mobile{
    display:none;
  }
}

.main-header{
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width:100%;
  z-index:1000;
  height: 180px;
  @include respond-to('medium'){
    background-color: #fff;
    position: fixed;
    height: 70px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
    .header-desktop{
      display: none;
    }
    .header-mobile{
      display:block;
      & > a{
        margin-#{$left}: 15px;
        margin-top: 5px;
        display: inline-block;
        float: $left;
        & > img{
          width: 200px;
        }
      }
    }
  }
}

.header-tel{
  float: $left;
  margin-top: 40px;
  &__txt{
    font-size: 17px;
    color: #1b263c;
    font-weight: 600;
  }
  &__number{
    font-size: 25px;
    color: #8e2a2b;
    font-weight: bold;
    direction: ltr;
    letter-spacing: 1px;
  }
}

.mobile-tel{
  display: inline-block;
  margin-top: 13px;
  i{
    color: #214678;
    font-size: 42px;
  }
}

.header-desktop{
  height: 100%;
}

.main-navi{
  display: inline-block;
  &__slash{
    position: relative;
    float: right;
    width: 1px;
    height: 22px;
    margin: 0 20px;
    vertical-align: middle;
    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 22px;
      background-color: #586579;
    }
  }
  & > ul{
    display: inline-block;
    list-style: none;
    padding: 0;
    padding-top: 70px;
    padding-#{$right}: 120px;
    margin: 0;
    line-height: 1;
    @include respond-to('large'){
      padding-#{$right}: 60px;
    }
    & > li{
      float: right;
      position: relative;
      padding-bottom: 50px;
      &:hover{
        & > ul{
          display: block;
        }
      }
      & > a{
        font-size: 22px;
        color: #1b263c;
        display: block;
      }
      & > ul{
        display: none;
        padding: 25px 0;
        margin: 0;
        list-style: none;
        background-color: #f7fbfb;
        position: absolute;
        z-index: 9;
        top: 70px;
        #{$right}: -20px;
        box-shadow: 0px 5px 30px 0px rgba(0,0,0,0.2);
        &:after {
        	bottom: 100%;
        	#{$right}: 40px;
        	border: solid transparent;
        	content: " ";
        	height: 0;
        	width: 0;
        	position: absolute;
        	pointer-events: none;
        	border-color: rgba(247, 251, 251, 0);
        	border-bottom-color: #f7fbfb;
        	border-width: 20px;
        	margin-left: -20px;
        }
        & > li{
          min-height: 50px;
          transition: all 0.3s;
          &:not(:last-child){
            border-bottom: 1px solid #bdbdbd;
          }
          &:hover{
            background-color: #dfe5e5;
          }
          & > a{
            font-size: 20px;
            font-weight: bold;
            color: #2e3749;
            display: block;
            padding: 0 40px;
            line-height: 50px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.logo{
  float: $right;
  margin: 50px 20px 0 0;
}

#fixed-top.scrolling{

}
