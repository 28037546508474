.wrapper-banners{
  position: relative;
  width: 100%;
  height: 520px;
  overflow: hidden;
  @include respond-to('medium'){
    margin-top: 70px;
    margin-bottom: 20px;
    height: 400px;
  }
}

.banner-item{
  width: 100%;
  min-height: 520px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include respond-to('medium'){
    min-height: 400px;
  }
}

.banner-data{
  position: absolute;
  top: 0;
  #{$right}: 100px;
  top: 70px;
  width: 30%;
  @include respond-to('medium'){
    top: 15px;
  }
  @include respond-to('small'){
    width: 100%;
    #{$right}: 15px;
    bottom: 0;
    top: auto;
  }
  @include respond-to('extra-small'){ 
    width: 95%;
  }
  &__title{
    font-size: 89px;
    line-height: 0.8;
    color: #8e2a2b;
    font-weight: 800;
    margin-bottom: 20px;
    @include respond-to('medium'){
      font-size: 62px;
    }
    @include respond-to('small'){
      font-size: 42px;
    }
    @include respond-to('extra-small'){
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  &__text{
    font-size: 20px;
    color: #10203f;
    max-height: 180px;
    overflow: hidden;
    @include respond-to('small'){
      font-size: 16px;
      max-height: unset;
    }
  }
}
