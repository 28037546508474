.wrapper-all-content{
  //background-color:#fff;
  position: relative;
  width: 100%;
}

.row-not-margin{
  margin: 0;
}

.col-not-padding{
  padding: 0;
}

#sb-site{
  background-color: transparent !important;
}

.hp-wrapper-boxes{
  margin: 0;
  div[class^="col-md"]{
    padding: 0;
  }
}

.hp-box-link{
  max-width: 300px;
  width: 100%;
  max-height: 265px;
  position: relative;
  display: block;
  overflow: hidden;
  @include respond-to('medium'){
    margin: 0 auto 20px;
  }

  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15,29,56,0.88);
    pointer-events: none;
    transition: all 0.2s;
  }
  &__title{
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    padding: 0 50px;
  }
  &:hover{
    &:before{
      background-color: rgba(142,42,43,0.9);
    }
  }
}

.hp-box-news,
.hp-box-articles{
  position: relative;
  background-color: #213050;
  width: 100%;
  height: 340px;
  text-align: center;
  &__title{
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
    display: inline-block;
    margin-top: 65px;
    margin-bottom: 40px;
  }
  &__text{
    font-size: 20px;
    color: #fff;
    max-height: 100px;
    overflow: hidden;
    padding: 0 60px;
    display: block;
    @include respond-to('larger'){
      padding: 0 15px;
    }
    &:hover,&:focus{
      color: #fff;
    }
  }
}

.hp-box-articles{
  background-color: #6f91ad;
  background-position: center;
  background-size: cover;
}

.hp-box-news-slider{
  .slick-dots{
    li{
      width:11px;
      height:11px;
      margin:0;
      margin-#{$left}:8px;
      button{
        width:11px;
        height:11px;
        border-radius: 50%;
        background-color: #36496c;
        opacity:1;
        border: 0;
        &:before{
          display:none;
        }
        &:hover{
          opacity:1;
          background-color: #a2c5d4;
        }
      }
      &.slick-active button{
        opacity:1;
        background-color: #a2c5d4;
      }
    }
  }
}

.hp-about{
  position: relative;
  width: 100%;
  height: 680px;
  @include respond-to('small'){
    height: auto;
    margin-bottom: 20px;
  }
  &__bg-image{
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    @include respond-to('small'){
      display: none;
    }
  }
}

.hp-about-data{
  background-color: #8e2a2b;
  max-width: 405px;
  width: 100%;
  min-height: 505px;
  max-height: 680px;
  position: absolute;
  top: 0;
  #{$right}: 0;
  @include respond-to('small'){
    min-height: unset;
    max-height: unset;
    max-width: none;
    position: static;
    padding: 20px 0;
  }
  &__title{
    font-size: 80px;
    font-weight: 200;
    color: #fff;
    text-align: center;
    margin: 60px 0 30px;
    line-height: 1;
    @include respond-to('small'){
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 62px;
    }
  }
  &__text{
    font-size: 20px;
    color: #fff;
    padding: 0 60px 0 50px;
    height: 242px;
    overflow: hidden;
    @include respond-to('small'){
      height: auto;
      padding: 20px;
    }
  }
  &__btn{
    display: block;
    font-size: 28px;
    color: #fff;
    text-align: center;
    margin-top: 10px;
    &:hover,&:focus{
      color: #fff;
      text-decoration: underline;
    }
  }
}
