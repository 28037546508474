.wrap-content{
  padding: 50px 70px;
  background-color: #fff;
  min-height: 100vh;
  @include respond-to('medium'){
    margin-top: 70px !important;
    padding: 15px 0;
  }
}

.title{
  font-size: 72px;
  font-weight: 600;
  line-height: 1;
  color: #10203f;
  margin: 15px 0 50px;
  @include respond-to('medium'){
    font-size: 42px;
    margin-bottom: 15px;
  }
  @include respond-to('small'){
    font-size: 22px;
  }

}

.hr-content{
  border-top: 1px solid #10203f;
  margin: 0 0 50px;
}

.content-text{
  h2{
    font-size: 30px;
    line-height: 1.2;
    color: #10203f;
    margin: 20px 0 20px;
    @include respond-to('small'){
      font-size: 22px;
    }
  }
  p{
    font-size: 20px;
    color: #10203f;
    @include respond-to('small'){
      font-size: 16px;
      text-align: justify;
    }
  }
  img{
    @include respond-to('small'){
      float: none !important;
      text-align: center !important;
    }
  }
}
